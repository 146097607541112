@font-face {
  font-family: "robotolight";
  src: url("../src/assets/style/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoregular";
  src: url("../src/assets/style/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "comfortalight";
  src: url("../src/assets/style/fonts/Comfortaa-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "comfortamedium";
  src: url("../src/assets/style/fonts/Comfortaa-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "comfortaregular";
  src: url("../src/assets/style/fonts/Comfortaa-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* ------ */
.active {
  color: red;
}

html {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
#icon {
  text-decoration: none;
  color: black;
  font-size: 6em;
  transition: all 0.5s;
}

#icon:hover {
  font-size: 7em;
}
.nav-bar ul {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
}
.nav-bar ul .li {
  text-decoration: none;
  color: black;
  transition: all 0.5s;
}
.nav-bar ul .li:hover {
  color: red;
}
.nav-bar ul li:active {
  background-color: transparent;
  color: black;
}
.skill a {
  font-size: 1.5em;
  padding-right: 1em;
  text-decoration: none;
  color: black;
  transition: all 0.5s;
}

.skill a:hover,
.favbooks li a:hover {
  font-size: 2em;
}

.favbooks li a {
  text-decoration: none;
  color: black;
  transition: all 0.5s;
}

/* header */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.myname {
  /* float: right; */
  font-size: 2.5em;
  text-decoration: underline;
  transition: all 0.5s;
}

.myname:hover {
  background-color: black;
  color: white;
}

.h1aboutme::first-letter,
.h1skill::first-letter,
.h1personal::first-letter,
.h1book::first-letter,
.h1interest::first-letter {
  font-size: 2.5em;
}

/* body */
body {
  background-image: url("./assets/img/background.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 60%;
  height: 100%;
  /* end of background */
  margin: auto;
  font-family: "comfortalight";
  color: black;
  user-select: none;
  /*remove ability to select anything from the page*/
}

.res-section {
  color: black;
  font-family: "comfortalight";
}

.aboutmebody,
.haiku,
.interest,
ol.skill,
.personal,
ol.favbooks {
  font-size: 1.2em;
  text-align: justify;
  margin: 3em;
  /* padding: 1em; */
  line-height: 2.5em;
}

.aboutmebody::first-letter,
p.interest::first-letter,
p.haiku::first-letter {
  font-size: 2em;
  font-weight: bold;
}

#mypicture {
  transition: all 1s;
  shape-margin: 2rem;
  width: 50%;
  float: right;
  margin: 0.2em;
  opacity: 0.8;
  padding-left: 0.8em;
}
#mypicture:hover {
  width: 50%;
  transition: 0.3s;
  shape-margin: 2rem;
  float: right;
  margin: 0.2em;
  opacity: 0.8;
}

.res-section {
  margin-bottom: 3em;
  font-family: "comfortalight";
}

ol.skill,
.personal,
ol.favbooks {
  font-family: "comfortalight";
  list-style-type: none;
}

.myname {
  transition: all 0.5s;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
  background-color: black;
}

.contact {
  text-decoration: none;
  color: black;
}

/* for MOBILE responsive purposes */

@media only screen and (max-width: 500px) {
  .clock {
    font-size: 0.5em;
  }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #icon {
    font-size: 2em;
    /* padding-right: 50%; */
  }
  .myname {
    font-size: 1em;
  }
  .nav-bar ul {
    font-size: 0.5em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 70%;
  }
  .nav-bar ul a {
    padding: 1em;
  }

  .mypicture {
    width: 90%;
    margin: auto;
    display: block;
    align-items: center;
    margin-bottom: 2em;
  }
  .aboutmebody,
  .haiku,
  .interest {
    text-align: center;
    margin: 1.5em;
    padding: 0.5em;
    font-size: 0.5em;
  }
  .res-section {
    font-size: 0.5em;
    font-family: "comfortalight";
  }
  .experience li,
  .skill li {
    list-style-type: none;
    margin-bottom: 1em;
    margin-right: 0.5em;
  }
  ol.skill,
  ol.experience {
    margin-right: 2em;
    font-family: "comfortalight";
  }
  footer {
    text-align: center;
    margin-top: 2em;
    font-size: 0.5em;
  }
  table.schedule {
    display: flex;
    right: 30px;
    width: 1px;
    text-align: center;
    font-size: 0.3em;
  }
  .schedhead {
    display: flex;
  }
  center {
    display: none;
  }
}
